import * as React from "react";
import Stack from "@mui/material/Stack";
import { QRCodeCanvas } from "qrcode.react";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { useUserSession } from "hooks/useUserSession";
import { useMutation } from "react-query";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { LoadingButton } from "components/LoadingButton/LoadingButton";

interface Props {
  user: CognitoUser;
  totpSecret: string;
  onSuccess: () => void;
  onClose: () => void;
}

const SetupTotp: React.FC<Props> = ({ user, totpSecret, onSuccess, onClose }) => {
  const { username } = useUserSession();
  const [authCode, setAuthCode] = React.useState<string>();

  const {
    mutate: verifyTotpToken,
    isLoading: isVerifyTotpTokenLoading,
    error: verifyTotpTokenError
  } = useMutation({
    mutationFn: async ({ user, code }: { user: any; code: string }) => {
      await Auth.verifyTotpToken(user, code);
      await Auth.setPreferredMFA(user, "TOTP");
    },
    onSuccess
  });

  const submitAuthCode = React.useCallback(() => {
    if (!authCode) {
      return;
    }
    window.console.log("Calling submitAuthCode");
    verifyTotpToken({ user, code: authCode });
  }, [authCode, user, verifyTotpToken]);

  return (
    <Stack spacing={2}>
      <Typography fontWeight="bold">Set up Authenticator App</Typography>
      <Stack>
        <Typography>1. Download an Authenticator Application</Typography>
        <Typography variant="caption">
          Install an Authenticator application on your smartphone. Search for 'authenticator' in your Application Store.
        </Typography>
      </Stack>
      <Stack>
        <Typography>2. Scan the QR Code</Typography>
        <Typography variant="caption">
          Use the installed authenticator application to scan the barcode below.
        </Typography>
      </Stack>
      <Stack alignItems="center">
        <QRCodeCanvas value={`otpauth://totp/AWSCognito:${username}?secret=${totpSecret}&issuer=LLS`} />
      </Stack>
      <Stack>
        <Typography>3. Enter The Authentication Code</Typography>
        <Typography variant="caption">Enter the 6-digit code displayed by your authenticator application</Typography>
      </Stack>
      <Stack alignItems="center" spacing={2}>
        <TextField
          placeholder="Enter code"
          variant="outlined"
          value={authCode}
          disabled={isVerifyTotpTokenLoading}
          onChange={e => setAuthCode(e.target.value)}
        />
        {verifyTotpTokenError && (
          <Typography color="error.main">
            Invalid code. Please try again or wait for the code to change and try then.
          </Typography>
        )}
        <Stack direction="row" spacing={1} alignItems="center">
          <LoadingButton isLoading={isVerifyTotpTokenLoading} variant="contained" onClick={submitAuthCode}>
            Submit
          </LoadingButton>
          <Button onClick={onClose}>Cancel</Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export { SetupTotp };
