import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { useMutation, useQuery } from "react-query";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { useUserSession } from "hooks/useUserSession";
import { DialogBody } from "components/DialogBody/DialogBody";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Switch from "@mui/material/Switch";
import { SetupTotp } from "./SetupTotp";
import { useDialogState } from "../../../../../../../hooks/useDialogState/useDialogState";
import { ConfirmationDialog } from "../../../../../../ConfirmationDialog/ConfirmationDialog";

export type MfaMethod = "TOTP" | "SMS" | "NOMFA" | "SMS_MFA" | "SOFTWARE_TOKEN_MFA";

interface Props {
  onClose: () => void;
}

enum MfaScreen {
  SUMMARY = "SUMMARY",
  TOTP_SETUP = "TOTP_SETUP"
}

const MfaSettingsDialog: React.FC<Props> = ({ onClose }) => {
  const { username } = useUserSession();
  const [mfaScreen, setMfaScreen] = React.useState<MfaScreen>(MfaScreen.SUMMARY);
  const [isDisableTotpDialogOpen, openDisableTotpDialog, closeDisableTotpDialog] = useDialogState();
  const {
    data: userAndMfa,
    refetch: refetchUser,
    isLoading: isUserAndMfaLoading,
    error: userAndMfaError
  } = useQuery(["gerUserAndPreferredMfa", username], {
    queryFn: async () => {
      window.console.log("Calling Auth.currentAuthenticatedUser");
      const user = await Auth.currentAuthenticatedUser();
      const preferredMfa = await Auth.getPreferredMFA(user);
      return { user, preferredMfa };
    },
    cacheTime: 0
  });

  const user = userAndMfa?.user as CognitoUser;
  const preferredMfa = userAndMfa?.preferredMfa as MfaMethod;

  const {
    mutate: setupTotp,
    data: totpSecret,
    isLoading: isSetupTotpLoading,
    error: setupTotpError
  } = useMutation(Auth.setupTOTP);

  const {
    mutate: setPreferredMFA,
    isLoading: isSetPreferredMFALoading,
    error: setPreferredMFAError
  } = useMutation({
    mutationFn: ({ user, mfaMethod }: { user: any; mfaMethod: MfaMethod }) => Auth.setPreferredMFA(user, mfaMethod),
    onSuccess: () => {
      refetchUser();
      closeDisableTotpDialog();
    }
  });

  const isTotpEnabled = preferredMfa === "TOTP" || preferredMfa === "SOFTWARE_TOKEN_MFA";

  return (
    <Dialog open={true} fullWidth={true} maxWidth="sm" onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Typography variant="h6">Multi-Factor Authentication Settings</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogBody error={userAndMfaError || setupTotpError} isLoading={isUserAndMfaLoading || isSetupTotpLoading}>
        <DialogContent sx={{ minHeight: 300 }}>
          {mfaScreen === MfaScreen.SUMMARY && (
            <Stack direction="row" spacing={2} sx={{ px: 1, py: 2 }} justifyContent="space-between">
              <Stack>
                <Typography variant="body1">Authenticator App</Typography>
                <Typography variant="caption">
                  You enter a 6 digit code (TOTP) generated by an authenticator application such as Microsoft
                  Authenticator or Google Authenticator.
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Switch
                  checked={isTotpEnabled}
                  onChange={async (_, checked) => {
                    if (checked) {
                      setupTotp(user, { onSuccess: () => setMfaScreen(MfaScreen.TOTP_SETUP) });
                    } else {
                      openDisableTotpDialog();
                    }
                  }}
                />
                <Typography variant="body2">{isTotpEnabled ? "Enabled" : "Disabled"}</Typography>
              </Stack>
            </Stack>
          )}

          {mfaScreen === MfaScreen.TOTP_SETUP && totpSecret && (
            <SetupTotp
              user={user}
              totpSecret={totpSecret}
              onSuccess={async () => {
                refetchUser();
                setMfaScreen(MfaScreen.SUMMARY);
              }}
              onClose={() => setMfaScreen(MfaScreen.SUMMARY)}
            />
          )}

          {isDisableTotpDialogOpen && (
            <ConfirmationDialog
              title="Disable Authenticator App MFA"
              content={<div>Are you sure you want to disable the Authenticator App MFA?</div>}
              onClose={closeDisableTotpDialog}
              onConfirm={() => setPreferredMFA({ user, mfaMethod: "NOMFA" })}
              isLoading={isSetPreferredMFALoading}
              error={setPreferredMFAError}
            />
          )}
        </DialogContent>
      </DialogBody>
    </Dialog>
  );
};

export { MfaSettingsDialog };
