import * as React from "react";
import Stack from "@mui/material/Stack";
import { useUserSession } from "hooks/useUserSession";
import { LabelValue } from "components/LabelValue/LabelValue";
import Button from "@mui/material/Button";
import { ChangePasswordDialog } from "../../ChangePasswordDialog";
import { useDialogState } from "hooks/useDialogState/useDialogState";
import { SnackbarAlert } from "components/SnackbarAlert/SnackbarAlert";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import { useQuery } from "react-query";
import { MfaMethod, MfaSettingsDialog } from "./MfaSettingsDialog/MfaSettingsDialog";
import Typography from "@mui/material/Typography";
import { AuthUtils } from "utils/AuthUtils";
import { UserAccount } from "gen/clients/llts";

interface Props {
  userAccount: UserAccount;
}

const ProfileTab: React.FC<Props> = ({ userAccount }) => {
  const { t } = useTranslation();
  const { username, email, firstName, lastName, isFederatedSsoAccount } = useUserSession();
  const [isChangePasswordOpen, openChangePassword, closeChangePassword] = useDialogState();
  const [isSuccessPasswordChangeOpen, openSuccessPasswordChange, closeSuccessPasswordChange] = useDialogState();
  const [isMfaSettingsOpen, openMfaSettings, closeMfaSettings] = useDialogState();

  const { data: userAndMfa, refetch: refetchUser } = useQuery(["gerUserAndPreferredMfa", username], {
    queryFn: async () => {
      window.console.log("Calling Auth.currentAuthenticatedUser");
      const user = await Auth.currentAuthenticatedUser();
      const preferredMfa = await Auth.getPreferredMFA(user);
      return { user, preferredMfa };
    },
    cacheTime: 0
  });
  const preferredMfa = userAndMfa?.preferredMfa as MfaMethod;

  const isMfaEnabled = preferredMfa && preferredMfa !== "NOMFA";

  const onChangePasswordSuccess = React.useCallback(() => {
    closeChangePassword();
    openSuccessPasswordChange();
  }, [closeChangePassword, openSuccessPasswordChange]);

  const onCloseMfaSettings = React.useCallback(async () => {
    await AuthUtils.refreshCurrentSession();
    refetchUser();
    closeMfaSettings();
  }, [closeMfaSettings, refetchUser]);

  const onChangePasswordClick = React.useCallback(() => {
    openChangePassword();
    closeSuccessPasswordChange();
  }, [openChangePassword, closeSuccessPasswordChange]);
  return (
    <>
      <Stack spacing={1} mt={4}>
        <LabelValue label={t("accountSettings.profile.username")} value={username} />
        <LabelValue
          label={t("accountSettings.profile.password")}
          value={
            <Stack direction="row" spacing={1} alignItems="center">
              <div>*********</div>
              {!isFederatedSsoAccount && (
                <Button onClick={onChangePasswordClick}>{t("accountSettings.profile.changePassword")}</Button>
              )}
            </Stack>
          }
        />
        {!isFederatedSsoAccount && userAccount.clientConfig?.isMfaEnabled && (
          <LabelValue
            label={t("accountSettings.profile.mfa.title")}
            value={
              <Stack direction="row" spacing={3} alignItems="center">
                <Typography>
                  {isMfaEnabled ? t("accountSettings.profile.mfa.enabled") : t("accountSettings.profile.mfa.disabled")}
                </Typography>
                <Button onClick={openMfaSettings} variant="outlined">
                  MFA Settings
                </Button>
              </Stack>
            }
          />
        )}
        {email && <LabelValue label={t("accountSettings.profile.email")} value={email} />}
        {firstName && <LabelValue label={t("accountSettings.profile.firstName")} value={firstName} />}
        {lastName && <LabelValue label={t("accountSettings.profile.lastName")} value={lastName} />}
      </Stack>
      {isChangePasswordOpen && (
        <ChangePasswordDialog onClose={closeChangePassword} onSuccess={onChangePasswordSuccess} />
      )}
      {isMfaSettingsOpen && <MfaSettingsDialog onClose={onCloseMfaSettings} />}
      {isSuccessPasswordChangeOpen && (
        <SnackbarAlert message={t("appHeader.userMenu.changePassword.title")} severity="success" />
      )}
    </>
  );
};

export { ProfileTab };
