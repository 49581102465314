/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientContactCollection } from '../models/ClientContactCollection';
import type { DisableClientContactAccountRequest } from '../models/DisableClientContactAccountRequest';
import type { DisableClientContactMfaRequest } from '../models/DisableClientContactMfaRequest';
import type { EnableClientContactAccountRequest } from '../models/EnableClientContactAccountRequest';
import type { IdentitySettings } from '../models/IdentitySettings';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class OrganizationAdminService {

    /**
     * Returns all users in an organization of the current user.  To have access to this endpoint, the current user must be an admin of the organization.
     * @returns ClientContactCollection A successful response.
     * @throws ApiError
     */
    public static listOrganizationClientContacts(): CancelablePromise<ClientContactCollection> {
        return __request({
            method: 'GET',
            path: `/userAccount/organizationClientContacts`,
        });
    }

    /**
     * Returns a client contact's identity settings
     * @returns IdentitySettings A successful response.
     * @throws ApiError
     */
    public static getIdentitySettings({
        clientContactId,
        email,
        includeMfaStatus,
    }: {
        /** Client contact ID **/
        clientContactId: number,
        /** client contact's email **/
        email: string,
        /** indicates if mfa status should be included into the response **/
        includeMfaStatus?: boolean,
    }): CancelablePromise<IdentitySettings> {
        return __request({
            method: 'GET',
            path: `/userAccount/organizationClientContacts/${clientContactId}/indentitySettings`,
            query: {
                'email': email,
                'includeMfaStatus': includeMfaStatus,
            },
        });
    }

    /**
     * Disable MFA for a client contact
     * @returns any A successful response.
     * @throws ApiError
     */
    public static disableMfa({
        clientContactId,
        requestBody,
    }: {
        /** client contact ID **/
        clientContactId: number,
        requestBody?: DisableClientContactMfaRequest,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/userAccount/organizationClientContacts/${clientContactId}/actions/disableMfa`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Enable account access for a client contact
     * @returns any A successful response.
     * @throws ApiError
     */
    public static enableAccount({
        clientContactId,
        requestBody,
    }: {
        /** client contact ID **/
        clientContactId: number,
        requestBody?: EnableClientContactAccountRequest,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/userAccount/organizationClientContacts/${clientContactId}/actions/enableAccount`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Disable account access for a client contact
     * @returns any A successful response.
     * @throws ApiError
     */
    public static disableAccount({
        clientContactId,
        requestBody,
    }: {
        /** client contact ID **/
        clientContactId: number,
        requestBody?: DisableClientContactAccountRequest,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/userAccount/organizationClientContacts/${clientContactId}/actions/disableAccount`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}