import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useMutation } from "react-query";
import { OrganizationAdminService } from "gen/clients/llts";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { LoadingButton } from "components/LoadingButton/LoadingButton";

interface Props {
  firstName?: string;
  lastName?: string;
  username: string;
  clientContactId: number;
  onClose: () => void;
  onSuccess: () => void;
}

const EnableAccountDialog: React.FC<Props> = ({
  username,
  clientContactId,
  firstName,
  lastName,
  onClose,
  onSuccess
}) => {
  const { mutate, isLoading, error } = useMutation(OrganizationAdminService.enableAccount);

  const onEnableAccountClick = React.useCallback(() => {
    mutate(
      {
        clientContactId,
        requestBody: {
          username
        }
      },
      { onSuccess }
    );
  }, [clientContactId, mutate, onSuccess, username]);

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>Enable Account</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            Are you sure you want to enable the account for {firstName} {lastName}?
          </Typography>
          {error && <ApiErrorMessage apiError={error} />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton isLoading={isLoading} onClick={onEnableAccountClick} variant="contained" color="error">
          Enable Account
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export { EnableAccountDialog };
