import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useMutation } from "react-query";
import { OrganizationAdminService } from "gen/clients/llts";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { LoadingButton } from "components/LoadingButton/LoadingButton";

interface Props {
  firstName?: string;
  lastName?: string;
  username: string;
  clientContactId: number;
  onClose: () => void;
  onSuccess: () => void;
}

const DisableMfaDialog: React.FC<Props> = ({ username, clientContactId, firstName, lastName, onClose, onSuccess }) => {
  const { mutate, isLoading, error } = useMutation(OrganizationAdminService.disableMfa);

  const onDisableMfaClick = React.useCallback(() => {
    mutate(
      {
        clientContactId,
        requestBody: {
          username
        }
      },
      { onSuccess }
    );
  }, [clientContactId, mutate, onSuccess, username]);

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>Disable MFA</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            Are you sure you want to disable MFA for {firstName} {lastName}?
          </Typography>
          {error && <ApiErrorMessage apiError={error} />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton isLoading={isLoading} onClick={onDisableMfaClick} variant="contained" color="error">
          Disable MFA
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export { DisableMfaDialog };
