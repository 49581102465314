import * as React from "react";
import { Box, Drawer } from "@mui/material";
import { DrawerHeader } from "components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "components/DrawerBody/DrawerBody";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { LanguageGroupsTab } from "./LanguageGroupsTab/LanguageGroupsTab";
import { ProfileTab } from "./ProfileTab/ProfileTab";
import { useUserSession } from "../../../../../hooks/useUserSession";
import { OrganizationUsersTab } from "./OrganizationUsersTab/OrganizationUsersTab";
import { UserAccountsService, UserGroup } from "../../../../../gen/clients/llts";
import { useQuery } from "react-query";

interface Props {
  onClose: () => void;
}

enum TabId {
  profile = "profile",
  languageGroups = "languageGroups",
  organizationUsers = "organizationUsers"
}

const AccountSettingsPanel: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const { roles, isFederatedSsoAccount, username } = useUserSession();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedTab, setSelectedTab] = React.useState<string>(TabId.profile);

  const {
    data: userAccount,
    isLoading: isUserAccountLoading,
    error: userAccountError
  } = useQuery(["getUserAccount", username], {
    queryFn: UserAccountsService.getUserAccount
  });

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <Drawer open={true} anchor="right" PaperProps={{ sx: { width: isSmallScreen ? "100vw" : "50vw" } }}>
      <DrawerHeader title={t("accountSettings.title")} onClose={onClose} />
      <DrawerBody isLoading={isUserAccountLoading} error={userAccountError}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs onChange={onTabChange} value={selectedTab}>
            <Tab value={TabId.profile} label={t("accountSettings.profile.title")} />
            <Tab value={TabId.languageGroups} label={t("accountSettings.languageGroups.title")} />
            {!isFederatedSsoAccount &&
              userAccount?.clientConfig?.isOrgManagementEnabled &&
              roles?.includes(UserGroup.CLIENT) && (
                <Tab value={TabId.organizationUsers} label={t("accountSettings.organizationUsers.title")} />
              )}
          </Tabs>
        </Box>
        {selectedTab === TabId.profile && userAccount && <ProfileTab userAccount={userAccount} />}
        {selectedTab === TabId.languageGroups && <LanguageGroupsTab />}
        {selectedTab === TabId.organizationUsers && <OrganizationUsersTab />}
      </DrawerBody>
    </Drawer>
  );
};

export { AccountSettingsPanel };
