import * as React from "react";
import { Box } from "@mui/material";
import { LoadingPage } from "../LoadingPage/LoadingPage";
import { ApiErrorMessage } from "../ApiErrorMessage/ApiErrorMessage";

interface Props {
  children: React.ReactNode;
  isLoading?: boolean;
  error?: any;
}

const DrawerBody: React.FC<Props> = ({ isLoading, error, children }) => {
  if (isLoading) {
    return (
      <Box sx={{ px: 3, py: 1 }}>
        <LoadingPage />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ px: 3, py: 1 }}>
        <ApiErrorMessage apiError={error} />
      </Box>
    );
  }

  return <Box sx={{ px: 3, py: 1 }}>{children}</Box>;
};

export { DrawerBody };
