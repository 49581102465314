import { Auth } from "@aws-amplify/auth";

export class AuthUtils {
  private static async refreshSessionPromise(refreshToken: any) {
    const user = await Auth.currentAuthenticatedUser();
    return new Promise((resolve, reject) => {
      return user.refreshSession(refreshToken, async (err: any, data: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(data); // THIS IS YOUR REFRESHED ATTRIBUTES/GROUPS
        }
      });
    });
  }
  public static async refreshCurrentSession() {
    const session = await Auth.currentSession();
    return this.refreshSessionPromise(session.getRefreshToken());
  }
}
