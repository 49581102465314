import * as React from "react";
import { useQueries, useQuery } from "react-query";
import { ClientContactSummary, OrganizationAdminService } from "gen/clients/llts";
import Stack from "@mui/material/Stack";
import { DataTable } from "components/DataTable/DataTable";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import TablePagination from "@mui/material/TablePagination";
import { IconButton, InputBase, Skeleton } from "@mui/material";
import { DataTableRowAction } from "components/DataTable/types";
import { useDialogState } from "hooks/useDialogState/useDialogState";
import { DisableMfaDialog } from "./DisableMfaDialog";
import { useUserSession } from "hooks/useUserSession";
import { DisableAccountDialog } from "./DisableAccountDialog";
import { Form, Formik } from "formik";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import { EnableAccountDialog } from "./EnableAccountDialog";

const OrganizationUsersTab: React.FC = () => {
  const { username } = useUserSession();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [searchInputBaseValue, setSearchInputBaseValue] = React.useState("");
  const [userFilter, setUserFilter] = React.useState("");
  const [selectedUserIndex, setSelectedUserIndex] = React.useState<number>();
  const [isDisableMfaDialogOpen, openDisableMfaDialog, closeDisableMfaDialog] = useDialogState();
  const [isDisableAccountDialogOpen, openDisableAccountDialog, closeDisableAccountDialog] = useDialogState();
  const [isEnableAccountDialogOpen, openEnableAccountDialog, closeEnableAccountDialog] = useDialogState();
  const { data, isLoading, error } = useQuery(["listOrganizationUsers"], {
    queryFn: OrganizationAdminService.listOrganizationClientContacts
  });

  const filteredData = React.useMemo(() => {
    if (!data) {
      return [];
    }
    return data.items.filter(
      contact =>
        contact.email?.toLowerCase().includes(userFilter.toLowerCase()) ||
        contact.firstName?.toLowerCase().includes(userFilter.toLowerCase()) ||
        contact.lastName?.toLowerCase().includes(userFilter.toLowerCase())
    );
  }, [data, userFilter]);

  const pageData = React.useMemo(() => {
    const sortedData = filteredData.sort((a, b) =>
      `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
    );
    return sortedData.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);
  }, [filteredData, currentPage, rowsPerPage]);

  const identitySettingsResults = useQueries(
    pageData.map(clientContactSummary => ({
      queryKey: ["getIdentitySettings", clientContactSummary.id],
      queryFn: () =>
        OrganizationAdminService.getIdentitySettings({
          clientContactId: clientContactSummary.id,
          email: clientContactSummary.email,
          includeMfaStatus: true //TODO: make this configurable
        }),
      retry: 0,
      retryOnMount: false
    }))
  );

  const accountStatusCell = React.useCallback(
    (contact: ClientContactSummary) => {
      const index = pageData.findIndex(c => c.id === contact.id);
      const { data: identitySettings, isLoading: areIdentitySettingsLoading } = identitySettingsResults[index];
      if (areIdentitySettingsLoading) {
        return <Skeleton variant="text" animation="wave" />;
      }
      return identitySettings?.isAccountEnabled ? "Enabled" : "Disabled";
    },
    [identitySettingsResults, pageData]
  );

  const mfaEnabledCell = React.useCallback(
    (contact: ClientContactSummary) => {
      const index = pageData.findIndex(c => c.id === contact.id);
      const { data: identitySettings, isLoading: areIdentitySettingsLoading } = identitySettingsResults[index];
      if (areIdentitySettingsLoading) {
        return <Skeleton variant="text" animation="wave" />;
      }
      return identitySettings?.isMfaEnabled ? "Yes" : "No";
    },
    [identitySettingsResults, pageData]
  );

  const onPageChange = React.useCallback((_, newPage) => {
    setCurrentPage(newPage);
  }, []);

  const onRowsPerPageChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
  }, []);

  const rowActions = React.useCallback(
    (row: ClientContactSummary, index: number): DataTableRowAction[] => {
      const identitySettings = identitySettingsResults[index]?.data;
      const isMfaEnabled = identitySettings?.isMfaEnabled;
      const isAccountEnabled = identitySettings?.isAccountEnabled;

      const actions: DataTableRowAction[] = [
        {
          title: "Disable MFA",
          disabled: !isMfaEnabled || !isAccountEnabled || identitySettings?.username === username,
          action: (_, index) => {
            setSelectedUserIndex(index);
            openDisableMfaDialog();
          }
        }
      ];

      if (isAccountEnabled && identitySettings?.username !== username) {
        actions.push({
          title: "Disable Account",
          action: (_, index) => {
            setSelectedUserIndex(index);
            openDisableAccountDialog();
          },
          disabled: false,
          color: "error.main"
        });
      }

      if (!isAccountEnabled && identitySettings?.username !== username) {
        actions.push({
          title: "Enable Account",
          action: (_, index) => {
            setSelectedUserIndex(index);
            openEnableAccountDialog();
          },
          disabled: false,
          color: "primary.main"
        });
      }

      return actions;
    },
    [identitySettingsResults, openDisableAccountDialog, openEnableAccountDialog, openDisableMfaDialog, username]
  );

  const selectedUser = React.useMemo(() => {
    if (selectedUserIndex === undefined) {
      return undefined;
    }
    return pageData[selectedUserIndex];
  }, [pageData, selectedUserIndex]);

  const selectedIdentitySettings = React.useMemo(() => {
    if (selectedUserIndex === undefined) {
      return undefined;
    }
    return identitySettingsResults[selectedUserIndex]?.data;
  }, [identitySettingsResults, selectedUserIndex]);

  const onUserIdentityChangeSuccess = React.useCallback(() => {
    if (selectedUserIndex) {
      const selectedUserIdentitySettingsResult = identitySettingsResults[selectedUserIndex];
      window.console.log("Calling selectedUserIdentitySettingsResult.refetch", selectedUserIdentitySettingsResult);
      if (selectedUserIdentitySettingsResult) {
        selectedUserIdentitySettingsResult.refetch();
      }
    }
    closeDisableMfaDialog();
    closeDisableAccountDialog();
    closeEnableAccountDialog();
  }, [
    closeDisableAccountDialog,
    closeEnableAccountDialog,
    closeDisableMfaDialog,
    identitySettingsResults,
    selectedUserIndex
  ]);

  return (
    <>
      <Stack mt={1}>
        <Formik
          initialValues={{}}
          onSubmit={() => {
            setCurrentPage(0);
            setUserFilter(searchInputBaseValue);
          }}
        >
          <Form>
            <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400, my: 2 }}>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search user..."
                onChange={event => setSearchInputBaseValue(event.currentTarget.value)}
              />
              <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Form>
        </Formik>
        <DataTable
          data={pageData}
          rowKey={(row: ClientContactSummary) => `${row.id}`}
          isLoading={isLoading}
          error={!!error && <ApiErrorMessage apiError={error} />}
          columns={[
            {
              id: "name",
              title: <strong>Name</strong>,
              cell: (row: ClientContactSummary) => `${row.firstName} ${row.lastName}`
            },
            {
              id: "email",
              title: <strong>Email</strong>,
              cell: (row: ClientContactSummary) => row.email
            },
            {
              id: "accountStatus",
              title: <strong>Account Status</strong>,
              cell: accountStatusCell
            },
            {
              id: "mfaEnabled",
              title: <strong>MFA Enabled</strong>,
              cell: mfaEnabledCell
            }
          ]}
          rowActions={rowActions}
        />
        {data && (
          <TablePagination
            component="div"
            count={filteredData.length}
            page={currentPage}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
            showFirstButton={true}
            showLastButton={true}
            rowsPerPageOptions={[10, 20, 30]}
          />
        )}
      </Stack>

      {isDisableMfaDialogOpen && selectedUser && selectedIdentitySettings && (
        <DisableMfaDialog
          username={selectedIdentitySettings.username}
          firstName={selectedUser.firstName}
          lastName={selectedUser.lastName}
          clientContactId={selectedUser.id}
          onClose={closeDisableMfaDialog}
          onSuccess={onUserIdentityChangeSuccess}
        />
      )}
      {isDisableAccountDialogOpen && selectedUser && selectedIdentitySettings && (
        <DisableAccountDialog
          username={selectedIdentitySettings.username}
          firstName={selectedUser.firstName}
          lastName={selectedUser.lastName}
          clientContactId={selectedUser.id}
          onClose={closeDisableAccountDialog}
          onSuccess={onUserIdentityChangeSuccess}
        />
      )}
      {isEnableAccountDialogOpen && selectedUser && selectedIdentitySettings && (
        <EnableAccountDialog
          username={selectedIdentitySettings.username}
          firstName={selectedUser.firstName}
          lastName={selectedUser.lastName}
          clientContactId={selectedUser.id}
          onClose={closeEnableAccountDialog}
          onSuccess={onUserIdentityChangeSuccess}
        />
      )}
    </>
  );
};

export { OrganizationUsersTab };
